import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/home": [5,[2]],
		"/home/admin/applications": [23,[2]],
		"/home/admin/features": [24,[2]],
		"/home/admin/feedback": [25,[2]],
		"/home/admin/permissions": [26,[2]],
		"/home/api": [27,[2]],
		"/home/labs": [28,[2]],
		"/home/[appId]": [6,[2]],
		"/home/[appId]/access": [7,[2]],
		"/home/[appId]/admin": [8,[2]],
		"/home/[appId]/customtickets": [9,[2]],
		"/home/[appId]/customtickets/[tileId]": [10,[2]],
		"/home/[appId]/featurerequest": [11,[2]],
		"/home/[appId]/featurerequest/[ticketId]": [12,[2]],
		"/home/[appId]/focus": [13,[2]],
		"/home/[appId]/incidents": [14,[2]],
		"/home/[appId]/knowledge": [15,[2]],
		"/home/[appId]/knowledge/[knowledgeId]": [16,[2]],
		"/home/[appId]/knowledge/[knowledgeId]/edit": [17,[2]],
		"/home/[appId]/notifications": [18,[2]],
		"/home/[appId]/replies": [19,[2]],
		"/home/[appId]/ticketcreate": [20,[2]],
		"/home/[appId]/tickets": [21,[2]],
		"/home/[appId]/tickets/[ticketId]": [22,[2]],
		"/new": [29,[3]],
		"/new/[app]": [30,[3]],
		"/new/[app]/[tileId]": [31,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';