import { env } from "$env/dynamic/public";
import { isSentryEnabled, sentryDSNUri } from "$lib/config";
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

if (isSentryEnabled) {
    Sentry.init({
        dsn: sentryDSNUri,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        profilesSampleRate: 1.0,
        environment: env.PUBLIC_STAGE,
        integrations: [replayIntegration(), new Sentry.BrowserProfilingIntegration()],
    });
}

export const handleError = handleErrorWithSentry();
